<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <perseu-card :title="titleDialog">
      <template slot="content">
        <v-form ref="form" @keyup.enter.native="save">
          <v-text-field
            label="Nome"
            v-model.trim="type.name"
            :rules="[$rules.required]"
          />
          <v-textarea
            outlined
            label="Explicação sobre"
            v-model.trim="type.explanation"
            maxlength="2000"
            counter
          />
          <select-lang v-model="type.lang" :rules="[$rules.required]" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn @click="save" color="primary">Salvar</v-btn>
      </template>
    </perseu-card>
  </v-dialog>
</template>

<script>
import { create, update } from "@/services/types-service";
export default {
  data: function () {
    return {
      type: {},
      dialog: false,
    };
  },
  computed: {
    titleDialog() {
      return (this.type.id ? "Editar " : "Novo ") + "Tipo";
    },
  },
  mounted() {
    this.$refs?.form?.reset();
  },
  methods: {
    create() {
      this.type = {};
      this.dialog = true;
    },
    edit(typeParam) {
      this.type = typeParam;
      this.dialog = true;
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        if (this.type.id) {
          const { data } = await update(this.type);
          this.$emit("update", data);
        } else {
          const { data } = await create(this.type);
          this.$emit("create", data);
        }
        this.dialog = false;
        this.$toasted.global.defaultSuccess();
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
